// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Location,
} from 'history'

// data
import {
    api_url_portfolio_content_list_site,
    reduxAuthStateSite,
    reduxCacheSetPageContentSite,
    reduxCacheStateSite,
    reduxModalStateSite,
} from 'data'

// serializers
import {
    PortfolioDetailSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
    getHostOriginSite,
    parseQuerySite,
} from 'services'

// main
export function getPageContentDataSite({
    axiosCancelToken,
    component,
    detailId,
    devId,
    forceRefresh,
    dispatch,
    errorStatus,
    extraFilter,
    infiniteContentId,
    isRandomOrdering,
    location,
    noEmpty,
    pageContentItems,
    pageNumberNew,
    pageSlug,
    portfolioObject,
    reduxAuth,
    reduxCacheSite,
    reduxModalSite,
    setErrorStatus,
    setIsLoading,
    setPageContentItems,
}: {
    forceRefresh?: boolean
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    detailId: string | undefined
    devId: string | undefined
    dispatch: React.Dispatch<any>
    errorStatus: errorStatusTypeSite
    extraFilter?: string
    infiniteContentId?: number
    isRandomOrdering?: boolean
    location: Location
    noEmpty?: boolean
    pageContentItems?: any[]
    pageNumberNew?: number
    pageSlug: string
    portfolioObject: PortfolioDetailSiteSerializer
    reduxAuth: reduxAuthStateSite
    reduxCacheSite: reduxCacheStateSite
    reduxModalSite: reduxModalStateSite
    setErrorStatus: React.Dispatch<errorStatusTypeSite>
    setIsLoading: React.Dispatch<boolean>
    setPageContentItems: any
}) {

    setErrorStatus({})

    if (noEmpty || reduxCacheSite.pages?.[pageSlug || portfolioObject.home_page_slug!]) {
        setIsLoading(false)
        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPageContentDataSite')
    } else {
        setPageContentItems([])
    }

    let tempPageContentApiUrl = `${api_url_portfolio_content_list_site}?portfolio_id=${portfolioObject.id}&page_slug=${pageSlug || portfolioObject.home_page_slug}`
    if (detailId) tempPageContentApiUrl += `&detail_id=${!isNaN(Number(detailId?.split('-')[0])) ? detailId?.split('-')[0] : detailId}`

    let pageContentApiUrl = getApiUrlSite(tempPageContentApiUrl, reduxModalSite)
    if (getHostOriginSite() === 'demo') pageContentApiUrl += `&profile_id=${devId?.split('-')[1]}`
    pageContentApiUrl += `&language_id=${reduxModalSite.languageId}`
    if (reduxModalSite.currencyId) pageContentApiUrl += `&currency_id=${reduxModalSite.currencyId}`
    const searchParams = parseQuerySite(location.search)
    const filter_query = searchParams.filter_query
    if (portfolioObject.search_pages?.map(page => page.slug).concat(['hub']).includes(pageSlug)) {
        const search_query = searchParams.query
        if (search_query) pageContentApiUrl += `&search_query=${search_query}&is_project=false`
        if (filter_query) pageContentApiUrl += `&filter_query=${filter_query}`
    } else if (filter_query?.includes('content_id-')) {
        pageContentApiUrl += `&filter_query=${filter_query}`
    }
    if (extraFilter) {
        pageContentApiUrl += `&filter_query_extra=${extraFilter}`
    }
    if (searchParams.force_active) pageContentApiUrl += `&force_active=${searchParams.force_active}`
    if (infiniteContentId) pageContentApiUrl += `&infinite_content_id=${infiniteContentId}`
    if (pageNumberNew) pageContentApiUrl += `&page_number=${pageNumberNew}`

    const formData: any = new FormData()
    if (isRandomOrdering && pageContentItems) {
        const idsArray: number[] = []
        pageContentItems.map((val: any) => {
            idsArray.push(val.id)
            return false
        })
        formData.append('ids_to_lahoun', idsArray.join(','))
        pageContentApiUrl += `&lahoun_length=${idsArray.length}`
    }

    if (!forceRefresh && reduxCacheSite.pageContents?.[pageContentApiUrl]) {
        setPageContentItems(reduxCacheSite.pageContents[pageContentApiUrl])
        setIsLoading(false)
        if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPageContentDataSite')
        return
    }

    if (process.env.NODE_ENV === 'development') console.log(pageContentApiUrl)

    axios({
        cancelToken: axiosCancelToken?.token,
        data: formData,
        headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
        method: isRandomOrdering ? 'post' : 'get',
        url: pageContentApiUrl,
    })
        .then((pageContentResponse) => {
            if (process.env.NODE_ENV === 'development') console.log(pageContentResponse)
            setPageContentItems(pageContentResponse.data.results)
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPageContentDataSite')
            dispatch(reduxCacheSetPageContentSite(pageContentApiUrl, pageContentResponse.data.results))
        })
        .catch((pageContentError) => {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) getPageContentDataSite')
            if (pageContentError.response?.status) {
                setErrorStatus({
                    ...errorStatus,
                    pageCcontent: pageContentError.response.status,
                })
            }
            axiosErrorHandlerSite({
                apiUrl: pageContentApiUrl,
                component,
                dispatch,
                error: pageContentError,
                reduxAuth,
                reduxModalSite,
                reference: 'getPageContentDataSite',
                skipAlertStatusCode: [403, 404],
                skipSendErrorStatusCode: [403, 404],
            })
        })
}
